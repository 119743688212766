<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent',}"
    style="margin-top: 0"
  >
			<div class="brand" style="padding-bottom: 0px;"><img src="/images/logo-black.png" alt=""> <span style="font-size: 15px;"> COPYAPES </span><h6 style="letter-spacing:1px;padding-left: 6px">跟单猿跟单平台</h6></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline">
				<a-menu-item>
					<router-link to="/dashboard">
						<span class="icon">
							<a-icon type="dashboard" theme="filled" class="m-0" />
						</span>
						<span class="label">数据看板</span>
					</router-link>
				</a-menu-item>
        <a-menu-item>
          <router-link to="/partner">
						<span class="icon">
							<a-icon type="team" theme="outlined" class="m-0"/>
						</span>
            <span class="label">合伙人计划</span>
          </router-link>
        </a-menu-item>
        <a-menu-item>
          <router-link to="/pricing">
						<span class="icon">
							<a-icon type="crown" theme="outlined" class="m-0"/>
						</span>
            <span class="label">会员服务</span>
          </router-link>
        </a-menu-item>

        <a-menu-item class="menu-item-header">
          TASKS
        </a-menu-item>

        <a-menu-item>
					<router-link to="/tasklist">
						<span class="icon">
							<a-icon type="dollar-circle" theme="filled" style="margin-right: 0" />
						</span>
						<span class="label">跟单列表</span>
					</router-link>
				</a-menu-item>
        <a-menu-item>
					<router-link to="/addtask">
						<span class="icon">
							<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<title>duplicate</title>
								<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="Tables" transform="translate(-58.000000, -507.000000)" fill="#BFBFBF" fill-rule="nonzero">
										<g id="sidebar" transform="translate(33.000000, 43.000000)">
											<g id="sign-up" transform="translate(16.000000, 455.000000)">
												<g id="duplicate" transform="translate(9.000000, 9.000000)">
													<path d="M4,6 C4,4.89543 4.89543,4 6,4 L12,4 C13.1046,4 14,4.89543 14,6 L14,12 C14,13.1046 13.1046,14 12,14 L6,14 C4.89543,14 4,13.1046 4,12 L4,6 Z" id="Path"></path>
													<path d="M2,0 C0.89543,0 0,0.89543 0,2 L0,8 C0,9.1046 0.89543,10 2,10 L2,4 C2,2.8954305 2.8954305,2 4,2 L10,2 L10,2 C10,0.89543 9.1046,0 8,0 L2,0 Z" id="Path"></path>
												</g>
											</g>
										</g>
									</g>
								</g>
							</svg>
						</span>
						<span class="label">创建跟单</span>
					</router-link>
				</a-menu-item>
<!--        <a-menu-item v-if="$store.state.userInfo.is_admin || $store.state.userInfo.is_vip">-->
        <a-menu-item>
          <router-link to="/bicoin">
            <span class="icon">
              <a-icon type="bold" theme="outlined" style="margin-right: 0"/>
            </span>
            <span class="label">币coin跟单</span>
          </router-link>
        </a-menu-item>
        <a-menu-item>
          <router-link to="/bitlanglang">
            <span class="icon">
              <a-icon type="fire" theme="outlined" style="margin-right: 0"/>
            </span>
            <span class="label">Bit浪浪</span>
          </router-link>
        </a-menu-item>

        <a-menu-item class="menu-item-header">
          SETTINGS
        </a-menu-item>

        <a-menu-item>
					<router-link to="/apilist">
						<span class="icon">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z" fill="#111827"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z" fill="#111827"/>
							</svg>
						</span>
						<span class="label">API管理</span>
					</router-link>
				</a-menu-item>
        <a-menu-item>
					<router-link to="/addapi">
						<span class="icon">
							<a-icon type="api" theme="filled"  style="margin-right: 0"/>
						</span>
						<span class="label">添加API</span>
					</router-link>
				</a-menu-item>
        <a-menu-item>
					<router-link to="/proxy">
						<span class="icon">
							<a-icon type="radar-chart" theme="outlined" style="margin-right: 0"/>
						</span>
						<span class="label">代理IP设置</span>
					</router-link>
				</a-menu-item>
        <a-menu-item>
          <router-link to="/settings">
						<span class="icon">
							<a-icon type="setting" theme="outlined" style="margin-right: 0"/>
						</span>
            <span class="label">个人中心</span>
          </router-link>
        </a-menu-item>

			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
<!--			<div class="aside-footer">-->
<!--				<div class="footer-box">-->
<!--					<span class="icon">-->
<!--						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--							<path d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827"/>-->
<!--							<path d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827"/>-->
<!--							<path d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827"/>-->
<!--						</svg>-->
<!--					</span>-->
<!--					<h6>需要帮助？</h6>-->
<!--					<p> 🚀 3分钟快速上手</p>-->
<!--					<a-button type="primary" href="https://txc.qq.com/products/647309/faqs-more/" block target="_blank">-->
<!--            帮助文档-->
<!--					</a-button>-->
<!--          <a-button type="primary" href="https://txc.qq.com/products/647309" block target="_blank" style="margin-top: 10px">-->
<!--            产品社区-->
<!--          </a-button>-->
<!--				</div>-->
<!--			</div>-->
			<!-- / Sidebar Footer -->

<!--			<a-button type="primary" href="https://www.creative-tim.com/product/muse-vue-ant-design-dashboard-pro" block target="_blank">-->
<!--				UPGRADE TO PRO-->
<!--			</a-button>-->

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

	import http from "@/utils/http";
  import store from "@/store";

  export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
			return {
				// sidebarCollapsedModel: this.sidebarCollapsed,
        is_admin: false,
        is_vip: false,
        is_private: false,
        is_partner: false,
			}
		},
    methods: {
      async refreshData() {
        const response = await http.get('/api/login/?token=' + localStorage.getItem("token"));
        if (response.data.code === 0) {
          const info = {
            username: response.data.data.name,
            id: response.data.data.id,
            is_admin: response.data.data.is_admin,
            is_vip: response.data.data.is_vip,
            is_private: response.data.data.is_private,
            is_partner: response.data.data.is_partner,
          }
          this.$store.commit('Login', info);
        } else {
          return;
        }
      }
    },
    created() {
      this.refreshData();
    },
	})

</script>
