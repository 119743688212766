import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: '跟单猿CopyApes',
		// redirect: '/sign-in',
		layout: "default",
		component: () => import('../views/Index.vue'),
		meta: {
			title: '跟单猿CopyApes丨让全世界顶级合约交易员为你打工',
			authorization: false,
		}
	},
	{
		path: '/contact',
		name: '联系我们',
		layout: "default",
		component: () => import('../views/Contact.vue'),
		meta: {
			title: '联系我们',
			authorization: false,
		}
	},
	{
		path: '/unsubscribe',
		name: '邮件退订',
		layout: "default",
		component: () => import('../views/Unsubscribe.vue'),
		props: route => ({ email: route.query.email }),
		meta: {
			title: '联系我们',
			authorization: false,
		}
	},
	// {
	// 	path: '/bitlanglang',
	// 	name: 'Bit浪浪',
	// 	layout: "dashboard",
	// 	component: () => import('../views/Bitlanglang.vue'),
	// 	meta: {
	// 		title: 'Bit浪浪',
	// 		authorization: true,
	// 	}
	// },
	{
		path: '/pricing',
		name: '产品价格',
		layout: "dashboard",
		component: () => import('../views/Pricing.vue'),
		meta: {
			title: '产品价格',
			authorization: false,
			// layoutClass: 'layout-pricing',
		}
	},
	{
		path: '/dashboard',
		name: '数据看板',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		meta: {
			title: '数据看板',
			authorization: true,
		}
	},
	{
		path: '/tasklist',
		name: '跟单列表',
		layout: "dashboard",
		component: () => import('../views/Tasklist.vue'),
		meta: {
			title: '跟单列表',
			authorization: true,
		}
	},
	{
		path: '/addtask',
		name: '创建跟单',
		layout: "dashboard",
		component: () => import('../views/Addtask.vue'),
		meta: {
			title: '创建跟单',
			authorization: true,
		}
	},
	{
		path: '/apilist',
		name: 'API管理',
		layout: "dashboard",
		component: () => import('../views/Apilist.vue'),
		meta: {
			title: 'API管理',
			authorization: true,
		}
	},
	{
		path: '/partner',
		name: '合伙人计划',
		layout: "dashboard",
		component: () => import('../views/Partner.vue'),
		meta: {
			title: '合伙人计划',
			authorization: true,
		}
	},
	{
		path: '/addapi',
		name: '添加API',
		layout: "dashboard",
		component: () => import('../views/Addapi.vue'),
		meta: {
			title: '添加API',
			authorization: true,
		}
	},
	{
		path: '/bicoin',
		name: '币coin跟单',
		layout: "dashboard",
		component: () => import('../views/Bicoin.vue'),
		meta: {
			title: '币coin跟单',
			authorization: true,
		}
	},
	{
		path: '/bitlanglang',
		name: 'Bit浪浪',
		layout: "dashboard",
		component: () => import('../views/Bitlanglang.vue'),
		meta: {
			title: 'Bit浪浪',
			authorization: true,
		}
	},
	{
		path: '/proxy',
		name: '代理IP设置',
		layout: "dashboard",
		component: () => import('../views/Proxy.vue'),
		meta: {
			title: '代理IP设置',
			authorization: true,
		}
	},
	// {
	// 	path: '/proxy-2',
	// 	name: '代理IP设置',
	// 	layout: "dashboard",
	// 	component: () => import('../views/Proxy-2.vue'),
	// 	meta: {
	// 		title: '代理IP设置',
	// 		authorization: true,
	// 	}
	// },
	// {
	// 	path: '/proxy-1',
	// 	name: '代理IP设置',
	// 	layout: "dashboard",
	// 	component: () => import('../views/Proxy-1.vue'),
	// 	meta: {
	// 		title: '代理IP购买',
	// 		authorization: true,
	// 	}
	// },
	{
		path: '/settings',
		name: '个人中心',
		layout: "dashboard",
		component: () => import('../views/Settings.vue'),
		meta: {
			title: '个人中心',
			authorization: true,
		}
	},
	{
		path: '/tasklist/tradedetails/:id',
		name: '交易详情',
		layout: "dashboard",
		component: () => import('../views/Tradedetails.vue'),
		meta: {
			title: '交易详情',
			authorization: true,
		}
	},
	{
		path: '/Profile',
		name: '个人用户信息',
		layout: "dashboard",
		meta: {
			title: '个人用户信息',
			layoutClass: 'layout-profile',
			authorization: true,
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: '用户登录',
		component: () => import('../views/Sign-In.vue'),
		meta: {
			title: '用户登录',
			authorization: false,
		}
	},
	{
		path: '/sign-up',
		name: '用户注册',
		component: () => import('../views/Sign-Up.vue'),
		meta: {
			// layoutClass: 'layout-sign-up',
			title: '用户注册',
			authorization: false,
		},
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title;
	if (to.meta.authorization && !store.state.isLogin) {
		next({ name: '用户登录' });
	} else if (to.meta.title == 'Sign-In' && store.state.isLogin) {
		next({ name: '数据看板' });
	}else {
		next();
	}
});

export default router
